import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
} from "react";
import { Primitive } from "../react-primitive";

/* -------------------------------------------------------------------------------------------------
 * Arrow
 * -----------------------------------------------------------------------------------------------*/

const NAME = "Arrow";

type ArrowElement = ElementRef<typeof Primitive.svg>;
type PrimitiveSvgProps = ComponentPropsWithoutRef<typeof Primitive.svg>;
interface ArrowProps extends PrimitiveSvgProps {}

const Arrow = forwardRef<ArrowElement, ArrowProps>((props, forwardedRef) => {
	const { children, width = 10, height = 5, ...arrowProps } = props;
	return (
		<Primitive.svg
			{...arrowProps}
			ref={forwardedRef}
			width={width}
			height={height}
			viewBox="0 0 30 10"
			preserveAspectRatio="none"
		>
			<title>Arrow</title>
			{/* We use their children if they're slotting to replace the whole svg */}
			{props.asChild ? children : <polygon points="0,0 30,0 15,10" />}
		</Primitive.svg>
	);
});

Arrow.displayName = NAME;

/* -----------------------------------------------------------------------------------------------*/

const Root = Arrow;

export {
	Arrow,
	//
	Root,
};
export type { ArrowProps };
